body, html {
    margin: 0;
    padding: 0;
    background-color: #E0D6C2;
}

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing-page .button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.landing-page button {
    border: none;
    background: transparent;
    padding: 20px;
    
}

.landing-page p {
    font-size: 40px;
    font-family: 'CustomFont', sans-serif; 
    text-align: center;
    font-weight: 400;
    line-height: 50px;
}

.landing-page .logo-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 631px;
    margin-top: 288px;
    font-family: 'CustomFont',
    sans-serif;
}