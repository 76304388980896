.meet-container {
    font-family: 'CustomFont', sans-serif;
}

.meet-subtitle {
    margin-top: 20px;
    margin-bottom: 25px;
    text-align: center;
}

.meet-subtitle2 {
    margin-top: 10px;
    margin-bottom: 50px;
    text-align: center;
}

.meet-content {
    margin-right: 100px;
    margin-left: 100px;
}

.personality-row {
    display: flex;
    align-items: flex-start;
    padding-left: 50px;
    margin-bottom: 50px;
}

.persona-image {
    width: 10%;
    object-fit: cover;
    margin-right: 50px;
}

.persona-description {
    width: 90%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 50px;
    font-size: 35px;
    border: 5px solid #702540;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);
}

.buddy-container {
    background-image: url('../../../public/images/Rainbow_Gradient.png');
    background-position: center;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 20px;
    padding-bottom: 20px;
    border: 5px solid #614918;
}

.buddy-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.buddy-column{
    text-align: center;
    margin: 0 10px;
}

.buddy-name{
    font-family: 'BabyDoll', sans-serif;
    font-size: 35px;
    text-align: center;
}

.buddy-description{
    max-width: 200px;
    word-wrap: break-word;
}
.buddy-image{

}

.footer-container{
    font-family: 'CustomFont', sans-serif;
    text-align: center;
    font-size: 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}