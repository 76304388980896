.chat-container {
    display: flex;
    height: 100vh;
    padding-left: 100px;
    padding-right: 100px;
    flex-direction: column;
    overflow: visible;
}

.chat-container.StinkyBoy {
    background-color: #CB99AB;
}

.chat-container.Maton {
    background-color: #97ADD0;
}

.chat-container.MeYo {
    background-color: #749977;
}

.chat-container.Buggy {
    background-color: #9B86AC;
}

.chat-container.Cocopups {
    background-color: #E58161;

}

.logo-link {
    display: block;
    text-align: center;
}

.logo-container {
    width: 631px;
    height: auto;
    margin-top: 50px;
    align-self: center;
}

.messages-container {
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.input-container {
    display: flex;
    position: relative;
    padding: 10px;
    border-radius: 100px;
}

.input-field {
    flex-grow: 1;
    margin-right: 10px;
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    text-align: left;
    padding-left: 50px;
    padding-right: 60px;
    font-size: 40px;
    font-family: 'CustomFont', sans-serif; 
}

.send-button {
    position: absolute;
    right: 50px;
    top: 0;
    height: 100%;
}