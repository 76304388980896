.send-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.send-button-image {
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
}