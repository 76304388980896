.message-container {
    text-align: left;
    font-size: 40px;
}

.message-container.sent {
    text-align: right;
}

.message-text {
    text-align: left;
    display: inline-block;
    width: 100%;
}

.message {
    position: relative;
    display: inline-block;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    margin: 5px 40px;
    background: rgba(255, 255, 255, 0.5); 
    border: 2px solid rgba(0, 0, 0, .5);
    color: black;
    word-wrap: break-word;
    max-width: 75%;
    font-family: 'CustomFont',
    sans-serif;
}

.message.received {
    padding-left: 50px;
}

.chat-icon {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 80px; /* Adjust as needed */
    height: 80px; /* Adjust as needed */
}