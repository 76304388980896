.purpose-container {
    font-family: 'CustomFont', sans-serif;
}
.purpose-subtitle {
    margin-top: 10px;
    text-align: center;
}

.purpose-content {
    margin-right: 100px;
    margin-left: 100px;
}

.thankyou-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer-container{
    font-family: 'CustomFont', sans-serif;
    text-align: center;
    font-size: 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}