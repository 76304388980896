.sidebar-container {
    position: static;
    z-index: 2;
}

.button-default {
    position: absolute;
    background: none;
    border: none;
    outline: none;
    z-index: 3;
    padding-left: 25px;
}

.button-open {
    position: absolute;
    background: none;
    border: none;
    outline: none;
    z-index: 3;
    padding-left: 25px;
}

.sidebar {
    position: absolute;
    width: 400px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #F0EDE3;
    padding: 50px;
    overflow: auto;
    font-size: 40px;
    font-family: 'CustomFont', sans-serif; 
    z-index: 2;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.sidebar ul {
    list-style-type: none;
    padding-left: 0;
    padding-top: 50px;
}

.sidebar ul li {
    margin-bottom: 20px;
}

.sidebar ul li:hover {
    color: #583B00; /* Change to the color you want */
}

.sidebar a, .sidebar a:visited, .sidebar a:active, .sidebar a:focus {
    color: inherit;
    text-decoration: none;
    font-family: 'BabyDoll', sans-serif; 
    font-size: 30px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}